<template>
  <div :class="['masonry-high', layout]">
    <div
      v-for="(column, index) in columns"
      class="column-box"
      :style="{ width: 100 / numberColumn + '%' }"
      :key="index"
    >
      <figure v-for="img in imageList(images, index)" :key="img.url">
        <img
          @load="(e) => e.target.classList.add('animate')"
          :src="img.images.fixed_height.url"
          alt=""
          @click="$emit('onSelected', img)"
        />
      </figure>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

export default class ImagesGrid<T> extends Vue {
  @Prop({ type: Array }) readonly images: Array<T> = []
  @Prop({ type: Number, default: 2 }) readonly numberColumn: number = 2
  @Prop({ type: String }) readonly layout: string = ''

  get columns(): Array<T> {
    return new Array(this.numberColumn)
  }

  imageList(images: Array<T>, columnIndex: number) {
    if (images.length >= this.numberColumn) {
      const numberStartSlice = Math.ceil(columnIndex * (images.length / this.numberColumn))
      const numberEndSlice = Math.ceil((columnIndex + 1) * (images.length / this.numberColumn))
      return images.slice(numberStartSlice, numberEndSlice)
    } else {
      return images.slice(columnIndex, columnIndex + 1)
    }
  }
}
</script>

<style lang="postcss" scoped>
.masonry-high {
  @apply flex flex-row flex-wrap;
}

.masonry-high .column-box {
  @apply flex flex-col;
}

.masonry-high .column-box img {
  @apply block w-full h-full opacity-0;
}

.masonry-high .column-box img {
  @apply opacity-100;
  transition: opacity 0.3s linear;
}

.masonry-high .column-box figure {
  margin-bottom: 6px;
  margin-left: 6px;
}
</style>
