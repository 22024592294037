
import uniqBy from 'lodash/uniqBy'
import reverse from 'lodash/reverse'
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IBoard, IBoardItem, IUser } from '@/utils/types'
import BoardViewItem from '@/components/board/BoardViewItem.vue'
import FireBoardItem from '@/services/board-items'
import { appendCssFont } from '@/utils/helpers'

@Options({
  components: { BoardViewItem },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class BoardViewItems extends Vue {
  @Prop()
  board!: IBoard

  boardItems: IBoardItem[] = []
  boardItemsFiltered: IBoardItem[] = []

  get loading() {
    return this.$store.state.board.loading
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  @Watch('boardItems', { deep: true })
  boardItemsChanged() {
    // Get latest update items by reverse
    this.boardItemsFiltered = reverse(uniqBy(reverse([...this.boardItems]), 'id'))
  }

  async getBoardItems() {
    FireBoardItem.board(this.board.id).on((boardItem: IBoardItem) => {
      if (boardItem.fireChangeType === 'removed') {
        this.boardItems = this.boardItems.filter((item) => item.id !== boardItem.id)
      } else {
        this.boardItems.push(boardItem)

        // apply font
        if (this.$store.state.board?.fontsList && this.$store.state.board.fontsList.length > 0) {
          const fontFamily = this.$store.state.board.fontsList.find(
            ({ family }: { family: string }) => family === boardItem?.fontFamily)

          appendCssFont(fontFamily?.family, fontFamily?.variants, fontFamily?.subsets)
        }
      }
    })
  }

  beforeDestroy() {
    FireBoardItem.off()
    this.boardItems = []
  }

  created() {
    this.getBoardItems()
  }
}
