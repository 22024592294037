<template>
  <Loading class="min-h-screen" :loading="loading" />
  <a-card class="shadow mb-4">
    <img v-if="boardItem.media" :src="boardItem.media" class="mb-4 rounded w-full" />

    <p class="text-lg" :style="{ 'font-family': boardItem.fontFamily }">
      {{ boardItem.message }}
    </p>
    <div class="flex items-end">
      <div class="flex-grow">
        <a-tooltip v-if="canEdit">
          <template #title>
            <span>Edit this message</span>
          </template>
          <a-button shape="circle" @click="onBoardItemClick">
            <template #icon><FormOutlined /></template>
          </a-button>
        </a-tooltip>

        <a-tooltip v-if="canEdit">
          <template #title>
            <span>Delete this message</span>
          </template>
          <a-popconfirm title="Are you sure delete this message?" ok-text="Yes" cancel-text="No" @confirm="onDelete">
            <a-button shape="circle" class="ml-2">
              <template #icon><DeleteOutlined /></template>
            </a-button>
          </a-popconfirm>
        </a-tooltip>
      </div>
      <div class="text-gray-400">From {{ boardItem.userName }}</div>
    </div>
  </a-card>

  <BoardItemFormModal
    v-if="modalVisibleBoardItem"
    :modalVisible="modalVisibleBoardItem"
    :board="board"
    :id="boardItem.id"
    @update:closeModal="modalVisibleBoardItem = false"
  />
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IBoard, IBoardItem, IUser } from '@/utils/types'
import { FormOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import BoardItemFormModal from '@/components/board/BoardItemFormModal.vue'
import Loading from '@/components/common/Loading.vue'
import FireBoardItem from '@/services/board-items'

@Options({
  components: {
    BoardItemFormModal,
    FormOutlined,
    DeleteOutlined,
    Loading,
  },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class BoardViewItem extends Vue {
  @Prop()
  boardItem!: IBoardItem

  @Prop()
  board!: IBoard

  modalVisibleBoardItem = false

  get loading() {
    return this.$store.state.board.loading
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get canEdit() {
    return this.boardItem.userId === this.userInfo.id
  }

  async onDelete() {
    if (this.boardItem.id) {
      await FireBoardItem.delete(this.boardItem.id)
    }
  }

  onBoardItemClick() {
    this.modalVisibleBoardItem = true
  }
}
</script>
