<template>
  <a-modal width="1000px" class="max-w-full" :visible="modalVisible" @cancel="onCancel" title="Select board background">
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="pattern" tab="Patterns"></a-tab-pane>
      <a-tab-pane key="solid" tab="Solids" force-render></a-tab-pane>
    </a-tabs>

    <!-- Items -->
    <div class="flex flex-wrap gap-4 mt-4">
      <div
        class="board-bg-item cursor-pointer relative w-40"
        :class="{
          'is-selected': bgItem.boardBg === board.boardBg,
        }"
        v-for="bgItem in boardBgList"
        :key="bgItem.boardBg"
        @click="setSelectedBoardBg(bgItem)"
      >
        <div class="board-bg-item__buttons" v-if="bgItem.id && bgItem.boardBg !== board.boardBg">
          <a-button class="bg-gray-50 border-0 text-white bg-opacity-5" @click="editBg($event, bgItem)">
            <template #icon><EditOutlined size="small" /></template>
          </a-button>
          <a-button class="bg-gray-50 border-0 text-white bg-opacity-5" @click="deleteBg($event, bgItem)">
            <template #icon><DeleteOutlined size="small" /></template>
          </a-button>
        </div>

        <div class="board-bg-item__title h-10" :style="{ background: bgItem.titleBg }"></div>
        <div class="board-bg-item__bg h-32" :style="[boardBackground(bgItem.boardBgThumb)]"></div>
      </div>
      <div class="board-bg-new w-40" @click="$emit('onCreateNew', true)">
        <PlusCircleOutlined class="mb-1 text-xl" />
        <span>Create Background</span>
      </div>
    </div>
    <template #footer>
      <a-button key="back" @click="onCancel">DONE</a-button>
    </template>
  </a-modal>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { Modal } from 'ant-design-vue'

import { IBoard, IBoardBackGround, IUser } from '@/utils/types'
import { LOAD_BOARD, UPDATE_BOARD, DELETE_BACKGROUND } from '@/store/actions'
import { DeleteOutlined, PlusCircleOutlined, EditOutlined } from '@ant-design/icons-vue'
import { BOARD_BACKGROUND_LIST } from '@/utils/constants'
import { boardBackground } from '@/utils/helpers'

@Options({
  components: { DeleteOutlined, PlusCircleOutlined, EditOutlined },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class BoardBackgroundSelect extends Vue {
  @Prop({ default: '' })
  id!: string

  @Prop()
  modalVisible!: boolean

  activeKey = 'pattern'

  form = {
    id: '',
    boardBg: BOARD_BACKGROUND_LIST[0].boardBg,
    titleBg: BOARD_BACKGROUND_LIST[0].titleBg,
  }

  boardBackground = boardBackground

  get boardBgList() {
    return this.fireBoardBgList
      .concat(BOARD_BACKGROUND_LIST)
      .filter((item: IBoardBackGround) => item.group === this.activeKey)
  }

  get fireBoardBgList() {
    return this.$store.state.background.items
  }

  get loading() {
    return this.$store.state.board.loading
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get board(): IBoard {
    return this.$store.state.board.item
  }

  async setSelectedBoardBg(bgItem: IBoardBackGround) {
    this.form = { ...this.form, ...bgItem }
    await this.onSubmit()
    this.getBoard()
  }

  async getBoard() {
    await this.$store.dispatch(LOAD_BOARD, this.id)
  }

  onSubmit() {
    if (this.form.id) {
      delete (this.form as IBoardBackGround).id
    }

    return this.$store.dispatch(UPDATE_BOARD, {
      ...this.board,
      ...this.form,
    })
  }

  @Emit('update:closeModal')
  onCancel() {
    return false
  }

  @Emit('onEditBg')
  onEditBg(item: IBoardBackGround) {
    return item
  }

  editBg(event: Event, item: IBoardBackGround) {
    this.onEditBg(item)
    event.stopPropagation()
  }

  deleteBg(event: Event, item: IBoardBackGround) {
    Modal.confirm({
      title: '',
      content: 'Do you want to delete this background?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        this.$store.dispatch(DELETE_BACKGROUND, { user: this.userInfo, id: item.id })
      },
    })
    event.stopPropagation()
  }

  async created() {
    if (this.id) {
      await this.getBoard()
      this.form = JSON.parse(JSON.stringify(this.board))
    }
  }
}
</script>
<style lang="scss" scoped>
.board-bg-item {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  &.is-selected {
    box-shadow: rgb(56 148 255) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
  }
  &__buttons {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.board-bg-new {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px dashed #d9d9d9;
  cursor: pointer;

  &:hover {
    border-color: red;
  }
}
</style>
