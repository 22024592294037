<template>
  <a-modal
    :visible="modalVisible"
    @cancel="onCancel"
    title="Add a message"
    :loading="loading"
    v-if="!isPasswordBoard || isValidPasswordInput"
  >
    <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">
      <a-tabs v-model:activeKey="activeTabImageKey">
        <a-tab-pane key="upload" tab="Upload image" class="mt-4">
          <Loading :loading="uploading" />
          <a-upload
            block
            v-model:file-list="fileList"
            name="file"
            accept=".png,.jpeg,.jpg,.gif"
            :showUploadList="false"
            :multiple="false"
            :before-upload="beforeUpload"
          >
            <a-button type="primary" ghost>
              <UploadOutlined />
              Select image
            </a-button>
          </a-upload>
        </a-tab-pane>
        <a-tab-pane key="gif" tab="GIF" force-render class="mt-4">
          <a-input-search v-model:value="searchGifKeyword" placeholder="input search text" @search="onSearchGif" />

          <div class="my-2 text-right">
            <a @click="onSearchGif('smile')"> Smile </a>
            <span> | </span>

            <a @click="onSearchGif('cats')"> Cats </a>
            <span> | </span>

            <a @click="onSearchGif('teamwork')"> Teamwork </a>
            <span> | </span>

            <a @click="onSearchGifTrend"> Trends </a>
          </div>

          <div class="max-h-96 overflow-auto">
            <GifGrid :images="searchGifResults" :numberColumn="2" @onSelected="onGifSelected($event)" />
          </div>
        </a-tab-pane>
      </a-tabs>

      <CloseOutlined v-if="this.form.media" @click="onRemoveGif" class="float-right" />
      <img v-if="form.media" :src="form.media" class="mt-2 rounded w-full" />

      <a-form-item label="What do you want to say?" name="message" class="mt-4">
        <a-textarea
          class="pr-10"
          :style="{'font-family': form.fontFamily}"
          v-model:value="form.message"
          autocomplete="message"
          placeholder="your message here"
          :rows="6"
        />
        <BoardEmojiSelector
          :isShowEmojiList="isShowEmoji"
          :buttonClass="'absolute mx-2 top-1 right-0 border-none px-2'"
          :listClass="'absolute top-8 right-1 z-10'"
          @update:showEmoji="isShowEmoji = $event"
          @update:onClose="isShowEmoji = $event"
          @update:emoji="form.message += $event"
        />
      </a-form-item>

      <div class="mt-4">
        <a-select v-model:value="selectedFont" class="static w-1/3" @change="onChangedFont">
          <a-select-option v-for="fontItem in fontsList" :key="fontItem.family" :value="fontItem">
            {{ fontItem.family }}
          </a-select-option>
        </a-select>

      </div>
    </a-form>

    <template #footer>
      <a-button key="back" @click="onCancel">Cancel</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="onSubmitBoard"> Save </a-button>
    </template>
  </a-modal>

  <!-- Board password type confirm form -->
  <a-modal :visible="modalVisible" @cancel="onCancel" title="Board password confirmation" :loading="loading" v-else>
    <a-form layout="vertical">
      <a-form-item label="Board password secret" name="boardPassword">
        <a-input v-model:value="boardPassword">
          <template #prefix><LockOutlined /></template>
        </a-input>
        <a-alert v-if="boardPasswordErrorMsg" :message="boardPasswordErrorMsg" type="error" show-icon class="mt-2" />
      </a-form-item>
      <a-form-item>
        <a-button key="submit" type="primary" @click="confirmPassword"> Confirm </a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IBoard, IFileItem, IUser } from '@/utils/types'
import { storageRef } from '@/utils/firebase'
import { appendCssFont } from '@/utils/helpers'
import { searchGiphy, searchGiphyTrend } from '@/services/giphy'
import { v4 as uuidv4 } from 'uuid'
import ImageUploadMixin from '@/components/mixins/ImageUploadMixin.vue'
import { BOARD_PERMISSION_TYPE } from '@/utils/constants'
import FireBoardItem from '@/services/board-items'

// components
import { DeleteOutlined, PlusOutlined, LockOutlined, UploadOutlined, CloseOutlined } from '@ant-design/icons-vue'
import GifGrid from '@/components/images/GifGrid.vue'
import BoardEmojiSelector from './BoardEmojiSelector.vue'
import Loading from '@/components/common/Loading.vue'

@Options({
  components: {
    DeleteOutlined,
    PlusOutlined,
    LockOutlined,
    UploadOutlined,
    CloseOutlined,
    GifGrid,
    BoardEmojiSelector,
    Loading,
  },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class BoardItemFormModal extends mixins(ImageUploadMixin) {
  @Prop()
  board!: IBoard

  @Prop({ default: '' })
  id!: string

  @Prop()
  modalVisible!: boolean

  fileList: IFileItem[] = []
  uploading = false

  searchGifKeyword = ''
  searchGifResults = []

  activeTabImageKey = 'upload'

  boardPassword = ''
  boardPasswordErrorMsg = ''
  isValidPasswordInput = false

  selectedFont = {
    family: '',
    variants: '',
    subsets: '',
  }

  requiredRule = {
    required: true,
    message: 'This field is required',
    trigger: 'blur',
  }

  form = {
    order: 0,
    boardId: '',
    media: '',
    message: '',
    userId: '',
    userName: '',
    fontFamily: '',
  }

  rules = {
    message: [this.requiredRule],
  }

  isShowEmoji = false

  get loading() {
    return this.$store.state.board.loading || this.uploading
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get isNew() {
    return !!this.board?.id
  }

  get isPasswordBoard() {
    return this.board.boardType === BOARD_PERMISSION_TYPE.password
  }

  get fontsList() {
    const fontsList = this.$store.state.board.fontsList

    if (this.form.fontFamily) {
      this.selectedFont = fontsList.find(({ family }: { family: string }) => family === this.form.fontFamily)
    } else {
      this.selectedFont = fontsList[0]
    }

    appendCssFont(this.selectedFont?.family, this.selectedFont?.variants, this.selectedFont?.subsets)
    return fontsList
  }

  @Watch('fileList')
  fileListChanged() {
    if (!this.fileList.length) {
      return
    }

    const file = this.fileList[0].originFileObj as Blob
    this.uploading = true
    storageRef
      .child(`${this.id || uuidv4()}.jpeg`)
      .put(file)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((publicUrl) => {
          this.form.media = publicUrl
        })
      })
      .finally(() => {
        this.uploading = false
      })
  }

  confirmPassword() {
    this.isValidPasswordInput = this.boardPassword === this.board.boardPassword
    if (!this.isValidPasswordInput) {
      this.boardPasswordErrorMsg = 'Invalid board password. Please try again!'
    } else {
      this.boardPasswordErrorMsg = ''
    }
  }

  onSubmitBoard() {
    this.$refs.formRef
      .validate()
      .then(() => {
        this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  onGifSelected(item: { images: { fixed_height: { url: string } } }) {
    this.form.media = item.images.fixed_height.url
    this.searchGifResults = []
  }

  onRemoveGif() {
    this.form.media = ''
  }

  onChangedFont() {
    appendCssFont(this.selectedFont.family, this.selectedFont?.variants, this.selectedFont?.subsets)
    this.form.fontFamily = this.selectedFont.family
  }

  async onSearchGif(keyword?: string) {
    this.searchGifResults = await searchGiphy(keyword || this.searchGifKeyword)
  }

  async onSearchGifTrend() {
    this.searchGifResults = await searchGiphyTrend()
  }

  async doSave() {
    if (!this.id && this.userInfo.id) {
      this.form.userId = this.userInfo.id
      this.form.userName = this.userInfo.displayName

      await FireBoardItem.add(this.form)
    } else {
      await FireBoardItem.update(this.form)
    }

    // Reload board list
    this.onCancel()
  }

  async getBoardItem() {
    return await FireBoardItem.get(this.id)
  }

  @Emit('update:closeModal')
  onCancel() {
    return false
  }

  async created() {
    if (this.id) {
      const boardItem = await this.getBoardItem()
      this.form = JSON.parse(JSON.stringify(boardItem))
    } else {
      this.form.boardId = this.board.id || ''
    }
  }
}
</script>
