
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

export default class ImagesGrid<T> extends Vue {
  @Prop({ type: Array }) readonly images: Array<T> = []
  @Prop({ type: Number, default: 2 }) readonly numberColumn: number = 2
  @Prop({ type: String }) readonly layout: string = ''

  get columns(): Array<T> {
    return new Array(this.numberColumn)
  }

  imageList(images: Array<T>, columnIndex: number) {
    if (images.length >= this.numberColumn) {
      const numberStartSlice = Math.ceil(columnIndex * (images.length / this.numberColumn))
      const numberEndSlice = Math.ceil((columnIndex + 1) * (images.length / this.numberColumn))
      return images.slice(numberStartSlice, numberEndSlice)
    } else {
      return images.slice(columnIndex, columnIndex + 1)
    }
  }
}
