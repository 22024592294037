<template>
  <a-modal :visible="modalVisible" @cancel="onCancel" title="Select board font">
    <a-select v-model:value="selectedFont" @change="onChangedFont" class="w-full">
      <a-select-option v-for="fontItem in fontsList" :key="fontItem.fontFamily" :value="fontItem">
        {{ fontItem.family }}
      </a-select-option>
    </a-select>

    <div class="text-2xl mt-6 text-center" :style="{ 'font-family': form.fontFamily }">Font preview</div>

    <template #footer>
      <a-button key="back" @click="onCancel">DONE</a-button>
    </template>
  </a-modal>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IBoard, IUser } from '@/utils/types'
import { LOAD_BOARD, UPDATE_BOARD } from '@/store/actions'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { BOARD_FONTS_LIST } from '@/utils/constants'
import { boardBackground, appendCssFont } from '@/utils/helpers'

@Options({
  components: { DeleteOutlined, PlusOutlined },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class BoardFontSelect extends Vue {
  @Prop({ default: '' })
  id!: string

  @Prop()
  modalVisible!: boolean

  activeKey = 'pattern'

  selectedFont = {
    family: '',
    variants: '',
    subsets: '',
  }

  form = {
    fontFamily: BOARD_FONTS_LIST[0].family,
  }

  boardBackground = boardBackground

  get fontsList() {
    const fontsList = this.$store.state.board.fontsList
    if (this.form.fontFamily) {
      this.selectedFont = fontsList.find(({ family }: { family: string }) => family === this.form.fontFamily)
    } else {
      this.selectedFont = fontsList[0]
    }

    appendCssFont(this.selectedFont?.family, this.selectedFont?.variants, this.selectedFont?.subsets)
    return fontsList
  }

  get loading() {
    return this.$store.state.board.loading
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get board(): IBoard {
    return this.$store.state.board.item
  }

  async onChangedFont() {
    appendCssFont(this.selectedFont.family, this.selectedFont?.variants, this.selectedFont?.subsets)

    this.form = { ...this.form, fontFamily: this.selectedFont.family }

    await this.onSubmit()
    this.getBoard()
  }

  async getBoard() {
    await this.$store.dispatch(LOAD_BOARD, this.id)
  }

  onSubmit() {
    return this.$store.dispatch(UPDATE_BOARD, {
      ...this.board,
      ...this.form,
    })
  }

  @Emit('update:closeModal')
  onCancel() {
    return false
  }

  async created() {
    if (this.id) {
      await this.getBoard()
      this.form = JSON.parse(JSON.stringify(this.board))
    }
  }
}
</script>
