<template>
  <!-- <div class="container-fluid m-auto py-6" :style="{ 'font-family': board.fontFamily }"> -->
  <!-- <a-row :gutter="16">
      <a-col :xs="24" :sm="24" :md="12" :lg="8">
        <BoardViewItem v-for="boardItem in itemsCol1" :key="boardItem.id" :boardItem="boardItem" :board="board" />
      </a-col>
      <a-col :xs="24" :sm="24" :md="12" :lg="8">
        <BoardViewItem v-for="boardItem in itemsCol2" :key="boardItem.id" :boardItem="boardItem" :board="board" />
      </a-col>
      <a-col :xs="24" :sm="24" :md="12" :lg="8">
        <BoardViewItem v-for="boardItem in itemsCol3" :key="boardItem.id" :boardItem="boardItem" :board="board" />
      </a-col>
    </a-row> -->
  <!-- </div> -->

  <!-- <div class="grid1" data-masonry='{ "itemSelector": ".grid-item", "columnWidth": 200 }'>
    <div class="grid-item" v-for="boardItem in boardItems" :key="boardItem.id">xx</div>
  </div> -->
  <div class="masonry container-fluid m-auto py-6" :style="{ 'font-family': board.fontFamily }">
    <div class="masonry__item" v-for="boardItem in boardItemsFiltered" :key="boardItem.id">
      <BoardViewItem :boardItem="boardItem" :board="board" />
    </div>
  </div>
</template>

<script lang="ts">
import uniqBy from 'lodash/uniqBy'
import reverse from 'lodash/reverse'
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IBoard, IBoardItem, IUser } from '@/utils/types'
import BoardViewItem from '@/components/board/BoardViewItem.vue'
import FireBoardItem from '@/services/board-items'
import { appendCssFont } from '@/utils/helpers'

@Options({
  components: { BoardViewItem },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class BoardViewItems extends Vue {
  @Prop()
  board!: IBoard

  boardItems: IBoardItem[] = []
  boardItemsFiltered: IBoardItem[] = []

  get loading() {
    return this.$store.state.board.loading
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  @Watch('boardItems', { deep: true })
  boardItemsChanged() {
    // Get latest update items by reverse
    this.boardItemsFiltered = reverse(uniqBy(reverse([...this.boardItems]), 'id'))
  }

  async getBoardItems() {
    FireBoardItem.board(this.board.id).on((boardItem: IBoardItem) => {
      if (boardItem.fireChangeType === 'removed') {
        this.boardItems = this.boardItems.filter((item) => item.id !== boardItem.id)
      } else {
        this.boardItems.push(boardItem)

        // apply font
        if (this.$store.state.board?.fontsList && this.$store.state.board.fontsList.length > 0) {
          const fontFamily = this.$store.state.board.fontsList.find(
            ({ family }: { family: string }) => family === boardItem?.fontFamily)

          appendCssFont(fontFamily?.family, fontFamily?.variants, fontFamily?.subsets)
        }
      }
    })
  }

  beforeDestroy() {
    FireBoardItem.off()
    this.boardItems = []
  }

  created() {
    this.getBoardItems()
  }
}
</script>
<style lang="scss" scoped>
.masonry {
  column-gap: 1.5em;
  &__item {
    break-inside: avoid;
  }
}

/* Masonry on large screens */
@media only screen and (min-width: 1024px) {
  .masonry {
    column-count: 3;
  }
}

/* Masonry on medium-sized screens */
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .masonry {
    column-count: 2;
  }
}

/* Masonry on small screens */
@media only screen and (max-width: 767px) and (min-width: 540px) {
  .masonry {
    column-count: 1;
  }
}
</style>
