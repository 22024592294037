import axios from 'axios'
import store from '@/store'
import { OPEN_DIALOG } from '@/store/actions'
import { GIPHY_API_KEY } from '@/utils/constants'

const axiosInstance = axios.create({
  baseURL: 'https://api.giphy.com/v1/gifs',
  timeout: 5000,
  withCredentials: false,
})

axiosInstance.interceptors.response.use((response) => {
  return response
}, (error) => {
  const errors = error.response?.data.errors
  switch (error.response?.status) {
    default:
      store.commit(OPEN_DIALOG, {
        title: 'Error',
        message: errors,
        confirmation: true,
        type: 'danger',
      })
      break
  }
})

const searchGiphy = async (keyword: string) => {
  const response = await axiosInstance.get('/search', {
    params: {
      q: keyword,
      limit: 24,
      api_key: GIPHY_API_KEY,
      fmt: 'json',
    },
  })

  return response?.data?.data || []
}

const searchGiphyTrend = async () => {
  const response = await axiosInstance.get('/trending', {
    params: {
      limit: 24,
      api_key: GIPHY_API_KEY,
      fmt: 'json',
    },
  })

  return response?.data?.data || []
}

export {
  searchGiphy,
  searchGiphyTrend,
}
