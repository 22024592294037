
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IBoard, IBoardItem, IUser } from '@/utils/types'
import { FormOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import BoardItemFormModal from '@/components/board/BoardItemFormModal.vue'
import Loading from '@/components/common/Loading.vue'
import FireBoardItem from '@/services/board-items'

@Options({
  components: {
    BoardItemFormModal,
    FormOutlined,
    DeleteOutlined,
    Loading,
  },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class BoardViewItem extends Vue {
  @Prop()
  boardItem!: IBoardItem

  @Prop()
  board!: IBoard

  modalVisibleBoardItem = false

  get loading() {
    return this.$store.state.board.loading
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get canEdit() {
    return this.boardItem.userId === this.userInfo.id
  }

  async onDelete() {
    if (this.boardItem.id) {
      await FireBoardItem.delete(this.boardItem.id)
    }
  }

  onBoardItemClick() {
    this.modalVisibleBoardItem = true
  }
}
