
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { Modal } from 'ant-design-vue'

import { IBoard, IBoardBackGround, IUser } from '@/utils/types'
import { LOAD_BOARD, UPDATE_BOARD, DELETE_BACKGROUND } from '@/store/actions'
import { DeleteOutlined, PlusCircleOutlined, EditOutlined } from '@ant-design/icons-vue'
import { BOARD_BACKGROUND_LIST } from '@/utils/constants'
import { boardBackground } from '@/utils/helpers'

@Options({
  components: { DeleteOutlined, PlusCircleOutlined, EditOutlined },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class BoardBackgroundSelect extends Vue {
  @Prop({ default: '' })
  id!: string

  @Prop()
  modalVisible!: boolean

  activeKey = 'pattern'

  form = {
    id: '',
    boardBg: BOARD_BACKGROUND_LIST[0].boardBg,
    titleBg: BOARD_BACKGROUND_LIST[0].titleBg,
  }

  boardBackground = boardBackground

  get boardBgList() {
    return this.fireBoardBgList
      .concat(BOARD_BACKGROUND_LIST)
      .filter((item: IBoardBackGround) => item.group === this.activeKey)
  }

  get fireBoardBgList() {
    return this.$store.state.background.items
  }

  get loading() {
    return this.$store.state.board.loading
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get board(): IBoard {
    return this.$store.state.board.item
  }

  async setSelectedBoardBg(bgItem: IBoardBackGround) {
    this.form = { ...this.form, ...bgItem }
    await this.onSubmit()
    this.getBoard()
  }

  async getBoard() {
    await this.$store.dispatch(LOAD_BOARD, this.id)
  }

  onSubmit() {
    if (this.form.id) {
      delete (this.form as IBoardBackGround).id
    }

    return this.$store.dispatch(UPDATE_BOARD, {
      ...this.board,
      ...this.form,
    })
  }

  @Emit('update:closeModal')
  onCancel() {
    return false
  }

  @Emit('onEditBg')
  onEditBg(item: IBoardBackGround) {
    return item
  }

  editBg(event: Event, item: IBoardBackGround) {
    this.onEditBg(item)
    event.stopPropagation()
  }

  deleteBg(event: Event, item: IBoardBackGround) {
    Modal.confirm({
      title: '',
      content: 'Do you want to delete this background?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        this.$store.dispatch(DELETE_BACKGROUND, { user: this.userInfo, id: item.id })
      },
    })
    event.stopPropagation()
  }

  async created() {
    if (this.id) {
      await this.getBoard()
      this.form = JSON.parse(JSON.stringify(this.board))
    }
  }
}
